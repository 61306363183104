export const styles = {
  mainContainer: {
    display: 'flex',
    flexFlow: 'row Wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },

  actions: {
    marginTop: 'auto',
  },

  formContainer: {
    width: '75%',
    paddingBottom: 10,
    paddingTop: 10,
    '@media (max-width: 768px)': {
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    marginRight: '1rem',
  },
  steps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    marginBottom: 30,
  },
  label: {
    marginTop: 20,
    paddingBottom: 20,
  },
  formLabel: {
    paddingBottom: 10,
  },
  grid: {
    marginBottom: 30,
  },
  afterAddress: {
    marginTop: 20,
    marginBottom: 30,
  },
};

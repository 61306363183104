import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox({ handleChange, state }) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box"
      options={countries}
      sx={{ width: 300 }}
      defaultValue={state.data.country}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Country"
          size="small"
          error={state.errors.country}
          required
        />
      )}
      onChange={handleChange('country')}
    />
  );
}

const countries = [
  'Afghanistan',
  'Argentina',
  'Australia',
  'Austria',
  'Bangladesh',
  'Belarus',
  'Belgium',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Canada',
  'Chile',
  'China Hong Kong',
  'China Macao',
  'China Mainland',
  'Colombia',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'DPR Korea',
  'Ecuador',
  'Egypt',
  'France',
  'Gambia',
  'Germany',
  'Ghana',
  'Greece',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Israel',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Korea',
  'Lebanon',
  'Luxembourg',
  'Macedonia',
  'Malta',
  'Mexico',
  'Mongolia',
  'Nepal',
  'Netherlands',
  'Nicaragua',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Saudi Arabia',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Tunisia',
  'Turkey',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uzbekistan',
  'Vietnam',
  'Yemen',
];

import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import { styles } from '../common/styles.js';

const ReceptionDetails = ({ state, handleChange, handleNext, handlePrev }) => {
  return (
    <CardContent style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in the Reception Committee Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="lcName"
            label="LC/CI Name"
            onChange={handleChange('lcName')}
            defaultValue={state.data.lcName}
            helperText="Format: LC xxxxxx"
            variant="outlined"
            size="small"
            fullWidth
            required
            error={state.errors.lcName}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="lcPhone"
            label="Phone No"
            onChange={handleChange('lcPhone')}
            defaultValue={state.data.lcPhone}
            variant="outlined"
            size="small"
            helperText="Format: +91 xxxxxxxxxx"
            fullWidth
            required
            error={state.errors.lcPhone}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="lcAlternativePhone"
            label="Alternative Phone No"
            onChange={handleChange('lcAlternativePhone')}
            defaultValue={state.data.lcAlternativePhone}
            variant="outlined"
            size="small"
            helperText="Format: +91 xxxxxxxxxx"
            fullWidth
            required
            error={state.errors.lcAlternativePhone}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="lcEmail"
            label="LC/CI Email Address"
            onChange={handleChange('lcEmail')}
            defaultValue={state.data.lcEmail}
            variant="outlined"
            size="small"
            helperText="Provide a valid email id"
            fullWidth
            required
            error={state.errors.lcEmail}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default ReceptionDetails;

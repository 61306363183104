import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import { styles } from '../common/styles.js';

const EmployerDetails = ({ state, handleChange, handleNext, handlePrev }) => {
  return (
    <CardContent style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in the Employer Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="employerName"
            label="Employer Name"
            onChange={handleChange('employerName')}
            defaultValue={state.data.employerName}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            required
            error={state.errors.employerName}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.label} justifyContent="center">
        <Grid item xs={12} sm={6}>
          Address:
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="employerAddress1"
            label="Line 1"
            onChange={handleChange('employerAddress1')}
            defaultValue={state.data.employerAddress1}
            variant="outlined"
            size="small"
            fullWidth
            error={state.errors.employerAddress1}
            required
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="employerAddress2"
            label="Line 2"
            onChange={handleChange('employerAddress2')}
            defaultValue={state.data.employerAddress2}
            variant="outlined"
            size="small"
            fullWidth
            error={state.errors.employerAddress2}
            required
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="employerAddress3"
            label="Line 3"
            onChange={handleChange('employerAddress3')}
            defaultValue={state.data.employerAddress3}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={styles.afterAddress}
        justifyContent="center"
      >
        <Grid item xs={12} sm={6}>
          <TextField
            name="phone"
            label="Phone Number"
            onChange={handleChange('phone')}
            defaultValue={state.data.phone}
            helperText="Format: +91 xxxxxxxxxx"
            variant="outlined"
            size="small"
            fullWidth
            required
            error={state.errors.phone}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="email"
            label="Email Address"
            onChange={handleChange('email')}
            defaultValue={state.data.email}
            variant="outlined"
            size="small"
            helperText="Provide a valid email id"
            fullWidth
            required
            error={state.errors.email}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="website"
            label="Website"
            onChange={handleChange('website')}
            defaultValue={state.data.website}
            variant="outlined"
            size="small"
            helperText="Website"
            fullWidth
            required
            error={state.errors.website}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default EmployerDetails;

import React from 'react';

import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const Header = ({ state, handleStepClick }) => {
  return (
    <CardContent>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <Stepper activeStep={state.stepCount} alternativeLabel>
          {state.steps.map((item, index) => (
            <Step key={item.label}>
              <StepLabel
                onClick={() => {
                  handleStepClick(index);
                }}
              >
                {item.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </CardContent>
  );
};

export default Header;

import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import inLocale from 'date-fns/locale/en-IN';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { styles } from '../common/styles.js';

const AdditionalDetails = ({ state, handleChange, handleDateChange }) => {
  return (
    <CardContent style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in the Additional Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="date"
              label="Date"
              value={state.dateObjects.date}
              onChange={handleDateChange('date')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item>
          <FormLabel style={styles.formLabel} component="legend">
            Select the Offer Type
          </FormLabel>
          <ToggleButtonGroup
            color="primary"
            value={state.data.offerType}
            exclusive
            onChange={handleChange('offerType')}
          >
            <ToggleButton value="Conventional">Conventional</ToggleButton>
            <ToggleButton value="Remote">Remote</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default AdditionalDetails;

import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import inLocale from 'date-fns/locale/en-IN';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { styles } from '../common/styles.js';
import ComboBox from '../Custom/ComboBox/ComboBox.jsx';

const StudentDetails = ({ state, handleChange, handleDateChange }) => {
  return (
    <CardContent style={styles.steps}>
      <Box style={styles.heading}>
        <Typography variant="h6" color="textPrimary" align="center">
          Fill in the Student Details
        </Typography>
      </Box>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="studentName"
            label="Student Name"
            onChange={handleChange('studentName')}
            defaultValue={state.data.studentName}
            variant="outlined"
            size="small"
            helperText="Details provided here should match official records"
            fullWidth
            required
            error={state.errors.studentName}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <ComboBox handleChange={handleChange} state={state} />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.label} justifyContent="center">
        <Grid item xs={12} sm={6}>
          Dates of the internship:
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={6} sm={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="startDate"
              label="Start Date"
              value={state.dateObjects.startDate}
              onChange={handleDateChange('startDate')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={inLocale}>
            <DesktopDatePicker
              name="endDate"
              label="End Date"
              value={state.dateObjects.endDate}
              onChange={handleDateChange('endDate')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={styles.grid} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <TextField
            name="referenceNo"
            label="Offer Reference No"
            onChange={handleChange('referenceNo')}
            defaultValue={state.data.referenceNo}
            variant="outlined"
            size="small"
            fullWidth
            required
            error={state.errors.referenceNo}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default StudentDetails;

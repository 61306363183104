import * as React from 'react';

import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import PrintIcon from '@mui/icons-material/Print';
import useMediaQuery from '@mui/material/useMediaQuery';

import { styles } from '../../common/styles';

export default function NavigationButtons({
  handlePrev,
  handleNext,
  handlePrint,
  step,
}) {
  const matches = useMediaQuery('(max-width:409px)');
  const getStepButtonContent = (step) => {
    if (step === 0) {
      return (
        <div style={styles.buttons}>
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            endIcon={<NavigateNext />}
          >
            Next
          </Button>
        </div>
      );
    } else if (step === 3) {
      return (
        <div style={styles.buttons}>
          <Button
            variant="contained"
            onClick={handlePrev}
            color="secondary"
            startIcon={<NavigateBefore />}
            style={styles.button}
          >
            Back
          </Button>

          <Button
            variant="contained"
            onClick={handlePrint('A')}
            color="secondary"
            startIcon={<PrintIcon />}
            style={styles.button}
          >
            {matches ? 'N5A' : 'Print N5A'}
          </Button>

          <Button
            variant="contained"
            onClick={handlePrint('B')}
            color="secondary"
            startIcon={<PrintIcon />}
          >
            {matches ? 'N5B' : 'Print N5B'}
          </Button>
        </div>
      );
    } else {
      return (
        <div style={styles.buttons}>
          <Button
            variant="contained"
            onClick={handlePrev}
            color="secondary"
            startIcon={<NavigateBefore />}
            style={styles.button}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            color="secondary"
            endIcon={<NavigateNext />}
            style={styles.button}
          >
            Next
          </Button>
        </div>
      );
    }
  };

  return (
    <CardActions style={styles.actions}>
      {getStepButtonContent(step)}
    </CardActions>
  );
}

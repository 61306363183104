import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Header from './Custom/Page Header/header';
import StudentDetails from './Pages/StudentDetails';
import EmployerDetails from './Pages/EmployerDetails';
import ReceptionDetails from './Pages/ReceptionDetails';
import AdditionalDetails from './Pages/AdditionalDetails';
import NavigationButtons from './Custom/NavigationButtons/NavigationButtons';

import GenerateN5A from './n5a';
import GenerateN5B from './n5b';

import { styles } from './common/styles.js';

class FormComponent extends Component {
  constructor(props) {
    super(props);

    const todayDate = new Date()
      .toJSON()
      .slice(0, 10)
      .split('-')
      .reverse()
      .join('/');

    this.state = {
      data: {
        studentName: '',
        country: 'India',
        startDate: todayDate,
        endDate: todayDate,
        referenceNo: '',

        employerName: '',
        employerAddress1: '',
        employerAddress2: '',
        employerAddress3: '',
        phone: '',
        email: '',
        website: '',

        lcName: '',
        lcEmail: '',
        lcPhone: '',
        lcAlternativePhone: '',

        date: todayDate,
        offerType: 'Conventional',
      },
      dateObjects: {},
      steps: [
        { label: 'Intern Details' },
        { label: 'Employer Details' },
        { label: 'Reception Committee Details' },
        { label: 'Additional Details' },
      ],
      fieldName: ['intern', 'employer', 'reception', 'additional'],
      fieldStatus: {
        intern: ['studentName', 'country', 'referenceNo'],
        employer: [
          'employerName',
          'employerAddress1',
          'employerAddress2',
          'phone',
          'email',
          'website',
        ],
        reception: ['lcName', 'lcPhone', 'lcAlternativePhone', 'lcEmail'],
        additional: ['offerType'],
      },
      errors: {},
      stepCount: 0,
      snackbarStatus: false,
    };
  }

  render() {
    const handlePrint = (formType) => (e, value) => {
      let dataRef = { ...this.state.data };

      // Replace space with N/A
      Object.keys(dataRef).forEach(function (key) {
        dataRef[key] = dataRef[key] || 'N/A';
      });
      if (!checkFilled()) {
        this.setState({ snackbarStatus: true });
        return;
      }
      if (formType === 'A') {
        GenerateN5A(dataRef);
      } else {
        GenerateN5B(dataRef);
      }
    };

    const handleChange = (input) => (e, value) => {
      const newValue =
        value === undefined || value === null ? e.target.value : value;
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [input]: newValue,
        },
      }));
    };

    const handleDateChange = (input) => (dateObject) => {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [input]:
            dateObject !== null && dateObject.toString() !== 'Invalid Date'
              ? dateObject.toJSON().slice(0, 10).split('-').reverse().join('/')
              : dateObject,
        },
        dateObjects: {
          ...prevState.dateObjects,
          [input]: dateObject,
        },
      }));
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      this.setState({ snackbarStatus: false });
    };

    const checkFilled = () => {
      let stepNo = this.state.stepCount;
      for (let i = 0; i <= stepNo; i++) {
        if (!checkPageFilled(i)) {
          let { stepCount } = this.state;
          stepCount = i;
          this.setState({ stepCount });
          return false;
        }
      }
      return true;
    };

    const checkPageFilled = (stepNo) => {
      const state = this.state;
      const data = state.data;
      const fieldName = state.fieldName[stepNo];
      const errors = {};
      if (fieldName) {
        const fields = state.fieldStatus[fieldName];
        const fieldLength = fields.length;
        for (var num = 0; num < fieldLength; num++) {
          if (data[fields[num]] === '') {
            errors[fields[num]] = true;
          }
        }
      }
      this.setState({ errors: errors });
      if (Object.keys(errors).length === 0 && errors.constructor === Object) {
        return true;
      }
      return false;
    };

    const handleNext = () => {
      if (!checkPageFilled(this.state.stepCount)) {
        this.setState({ snackbarStatus: true });
        return;
      }
      let { stepCount } = this.state;
      stepCount = stepCount + 1;
      this.setState({ stepCount });
    };

    const handlePrev = () => {
      let { stepCount } = this.state;
      stepCount = stepCount - 1;
      this.setState({ stepCount });
    };

    const handleStepClick = (index) => {
      let { stepCount } = this.state;
      stepCount = index;
      this.setState({ stepCount });
    };

    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return (
            <StudentDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handleDateChange={handleDateChange}
            />
          );
        case 1:
          return (
            <EmployerDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleDateChange={handleDateChange}
            />
          );
        case 2:
          return (
            <ReceptionDetails
              state={this.state}
              handleChange={handleChange}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          );
        case 3:
          return (
            <AdditionalDetails
              state={this.state}
              handleChange={handleChange}
              handlePrev={handlePrev}
              handleDateChange={handleDateChange}
              handlePrint={handlePrint}
            />
          );
        default:
          return <h1>Error</h1>;
      }
    };

    return (
      <Card className=".MuiCard-root">
        <CardMedia
          component="img"
          height="150"
          image="/images/header (3).png"
          alt="Iaeste India Logo"
        />

        <Header state={this.state} handleStepClick={handleStepClick} />

        <Grid item style={{ width: '100%', overflow: 'auto' }}>
          {getStepContent(this.state.stepCount)}
          <Snackbar
            open={this.state.snackbarStatus}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: '100%' }}
              variant="filled"
            >
              Fill all the required entries.
            </Alert>
          </Snackbar>
        </Grid>
        <NavigationButtons
          handlePrev={handlePrev}
          handleNext={handleNext}
          handlePrint={handlePrint}
          step={this.state.stepCount}
        />
      </Card>
    );
  }
}

FormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormComponent);
